/* Hide scrollbar for Chrome, Safari and Opera */
.auto-hide-scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1); /* For Firefox */
  }
  
  .auto-hide-scrollbar::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .auto-hide-scrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Track color */
  }
  
  .auto-hide-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2); /* Thumb color */
    border-radius: 10px; /* Optional: Add a rounded corner to the scrollbar thumb */
  }
  
  .auto-hide-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4); /* Thumb color when hovered */
  }
  