/* src/Privacy.css */

.privacy-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F55%2Fe2%2Fb2%2F55e2b2171897530365e1d28ed4f5bbd9.png&f=1&nofb=1&ipt=c9b1c631861c240f22201fcb7b9ca90b2abca85bfc3e945e88db237f0aa04130&ipo=images"); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    color: white;
    padding: 20px;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1;
  }
  
  .content-container {
    position: relative;
    z-index: 2; /* Ensures the content is above the overlay */
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    padding: 20px;
    max-height: 80vh; /* Makes the container scrollable */
    overflow-y: auto;
  }
  