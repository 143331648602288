.blog-card {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }
  
  /* Image section */
  .blog-card-image img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Content section */
  .blog-card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .blog-card-title {
    margin: 0 0 10px 0;
    font-size: 1.6rem;
    font-family: 'Baskic8', serif; /* or your desired font */
    color: #fff;
  }
  
  .blog-card-meta {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 10px;
    font-size: 0.9rem;
    color: #ccc;
  }
  
  /* .blog-card-author,
  .blog-card-date {
    /* additional styling if needed 
  } */
  
  .blog-card-body {
    line-height: 1.6;
    color: #ddd;
    margin-bottom: 15px;
  }
  
  .blog-card-body p {
    margin-bottom: 1rem;
  }
  
  /* Footer with Read More link */
  .blog-card-footer {
    text-align: right;
  }
  
  .read-more {
    color: #ffab00;
    font-weight: bold;
    text-decoration: none;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  