/* .carousel.carousel-slider {
overflow: hidden;
} */

/* .carousel .slider-wrapper {
overflow: hidden;
margin: 0 auto;
width: 100%;
} */

/* .carousel .slide {
    flex: 1 0 auto;
    width: 20%;
  } */

/* .carousel.carousel-slider .control-arrow {
opacity: 1;
} */

.carousel .carousel-status {
display: none;
}
