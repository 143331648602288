/* src/GameImage.css */

.game-image-container {
    position: relative;
    padding: 0 10px;
}

.game-image {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    width: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    backdrop-filter: blur(25px);
    text-align: center;
    padding: 30px;
}

.overlay.hover {
    opacity: 1;
}

.game-title {
    font-family: 'Baskic8';
    font-size: 2vw; /* Use viewport width units for responsive font size */
}

.overlay-text {
    font-family: 'Baskic8';
    font-size: 1.5vw; /* Use viewport width units for responsive font size */
}

.discover-button {
    margin-top: 1em; /* Base margin */
    font-size: 1vw; /* Use viewport width units for responsive font size */
    padding: 0.5em 1em; /* Base padding */
    border-radius: 20px;
    font-family: 'Baskic8';
    font-weight: bold;
    background-color: #CB905A;
    text-align: center;
}

/* Media Queries for finer control */
@media (max-width: 768px) {
.game-title {
    font-size: 4vw;
}
.overlay-text {
    font-size: 2vw;
}
.discover-button {
    font-size: 3vw;
    padding: 0.6em 1.2em;
}
}

@media (min-width: 769px) {
.game-title {
    font-size: 2.5vw;
}
.overlay-text {
    font-size: 1.5vw;
}
.discover-button {
    font-size: 1.5vw;
    padding: 0.5em 1em;
}
}

@media (min-width: 1024px) {
.game-title {
    font-size: 2vw;
}
.overlay-text {
    font-size: 1vw;
}
.discover-button {
    font-size: 1vw;
    padding: 0.4em 0.8em;
}
}
  